<template>
	<div class="wrap">
		<div class="header">产品管理</div>
		<div class="content">
			<el-tabs id="tabs" v-model="activeName" @tab-click="handleClick">
				<el-tab-pane :label="`已上线 [${total1}]`" name="1"></el-tab-pane>
				<el-tab-pane :label="`审核中 [${total0}]`" name="0"></el-tab-pane>
				<el-tab-pane :label="`审核拒绝 [${total2}]`" name="2"></el-tab-pane>
				<el-tab-pane :label="`已下线 [${total3}]`" name="3"></el-tab-pane>
			</el-tabs>
			<el-button class="add-btn" type="primary" plain @click="handleAdd">发布新产品</el-button>
			<div class="tip">
				<p>小提示：</p>
				<p v-if="level == 1" :key="Math.random()">
					<b>·</b>&nbsp;&nbsp;&nbsp;您目前已发布{{goodsCount}}条产品,还可发布{{surplusCount}}条。产品条数不够用？<a
						:href="$store.state.ROOT_URL+'/vip'" target="_blank">点此升级会员</a>
				</p>
				<p v-else :key="Math.random()">
					<b>·</b>&nbsp;&nbsp;&nbsp;您目前已发布{{goodsCount}}条产品。
					<!-- 产品条数不够用？-->
					<a :href="$store.state.ROOT_URL+'/vip'" target="_blank">点此升级会员</a> 
				</p>
				<!-- <p><b>·</b>&nbsp;&nbsp;&nbsp;您目前已发布{{goodsCount}}条产品。产品条数不够用？<a :href="$store.state.ROOT_URL+'/vip'"
						target="_blank">点此升级会员</a>
				</p> -->
				<p><b>·</b>&nbsp;&nbsp;&nbsp;升级会员服务，立享10000条产数量。</p>
				<p><b>·</b>&nbsp;&nbsp;&nbsp;每日刷新有助于提升站内排名，每刷新一次消耗10积分。</p>
			</div>
			<el-form class="screen" :inline="true">
				<el-form-item label="查找产品:">
					<el-input v-model="search" placeholder="请输入产品名称"></el-input>
				</el-form-item>
				<el-form-item>
					<el-select v-model="cate" placeholder="请选择产品分类">
						<el-option v-for="item in cateList" :label="item.catname" :value="item.id" :key="item.id" />
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="stars" placeholder="质量星级">
						<el-option label="一星" value="1"></el-option>
						<el-option label="二星" value="2"></el-option>
						<el-option label="三星" value="3"></el-option>
						<el-option label="四星" value="4"></el-option>
						<el-option label="五星" value="5"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button @click="handleSearch">查找</el-button>
				</el-form-item>
			</el-form>
			<el-table :data="newList" ref="productTable" style="width: 100%" stripe @selection-change="handleSelect">
				<el-table-column type="selection"></el-table-column>
				<el-table-column label="商品" width="350">
					<div class="tit-wrap" slot-scope="scope">
						<div class="tit-img">
							<a :href="$store.state.ROOT_URL+'/product/'+scope.row.id" target="_blank">
								<img :src="scope.row.thumb" />
							</a>
						</div>
						<div class="tit-info">
							<div class="tit-name">
								<span>{{scope.row.title}}</span>
								<el-popover placement="bottom" trigger="click" @show="popoverTitleShow(scope.row)">
									<el-form class="edit-name-form" inline size="mini">
										<el-form-item label="商品名称">
											<el-input v-model="scope.row.title2" />
										</el-form-item>
										<el-form-item>
											<el-button type="primary" @click="handleEditTitle(scope.row)">确定
											</el-button>
										</el-form-item>
									</el-form>
									<i class="el-icon-edit" slot="reference" />
								</el-popover>
							</div>
							<p class="tit-num">
								<span>被访问次数:{{scope.row.view_num}}</span>
								<span>第{{scope.row.refreshs}}次刷新</span>
							</p>
							<!-- <el-popover placement="bottom" width="150" trigger="click">
								<div class="share-qr">
									<img src="" />
								</div>
								<p class="tit-qr" slot="reference">
									<i class="icon-qr"></i>
									<span>微信扫码分享</span>
								</p>
							</el-popover> -->

						</div>
					</div>
				</el-table-column>
				<el-table-column label="价格" v-if="activeName!=2" :key="Math.random()">
					<div class="money-wrap" slot-scope="scope">
						<span class="priceMoney">{{scope.row.money}}元</span>
						<el-popover placement="bottom" trigger="click" @show="popoverMoneyShow(scope.row)">
							<el-form class="edit-name-form" inline size="mini">
								<el-form-item label="商品价格">
									<el-input v-model="scope.row.money2" />
								</el-form-item>
								<el-form-item>
									<el-button type="primary" @click="handleEditMoney(scope.row)">确定
									</el-button>
								</el-form-item>
							</el-form>
							<i class="el-icon-edit" slot="reference" />
						</el-popover>
						<br/>
						<div  class="ships" v-if="flagShip == 1"> 
							<p>高级:{{ (scope.row.money2*scope.row.ratio1).toFixed(0)}}元</p>
							<p>首席:{{(scope.row.money2*scope.row.ratio2).toFixed(0)}}元</p>
						</div>
					</div>
				</el-table-column>
				<el-table-column prop="add_time" label="发布时间"></el-table-column>
				<template v-if="activeName!=2" :key="Math.random()">
					<el-table-column label="质量星级" width="220px">
						<template slot-scope="scope">
							<div class="stars" style="margin-right: 1px;display: inline-block;"
								v-for="item in scope.row.stars"></div>
						</template>
					</el-table-column>
					<el-table-column label="热销" width="100px">
						<template slot-scope="scope">
							<el-switch v-model="scope.row.is_hot" :active-value="1" :inactive-value="0"
								@change="handleHotChange(scope.row)" />
					</el-table-column>
					<el-table-column label="新品" width="100px">
						<template slot-scope="scope">
							<el-switch v-model="scope.row.is_best" :active-value="1" :inactive-value="0"
								@change="handleBestChange(scope.row)" />
						</template>
					</el-table-column>
					<el-table-column prop="stock" label="库存" width="100px"></el-table-column>
				</template>
				<el-table-column prop="remark" label="拒绝原因" v-else  :key="Math.random()" />
				<el-table-column label="操作" width="350px">
					<template slot-scope="scope">
						<el-button type="text" size="small" v-if="activeName==1" @click="handleToSee(scope.row)">
							查看
						</el-button>
						<el-button type="text" size="small" v-if="activeName==1 || activeName==2"
							@click="handleToRevise(scope.row)">修改
						</el-button>
						<el-button @click="handleRepeat(scope.row)" type="text" size="small" v-if="activeName==1">复制
						</el-button>
						<el-button @click="handleDelete(scope.row)" type="text" size="small">删除</el-button>
						<el-button v-if="activeName==1" @click="handleRefresh(scope.row)" type="text" size="small">刷新</el-button>
						<el-button v-if="activeName==3" @click="handleup(scope.row)" type="text" size="small" >上架</el-button>
						<el-button v-if="flagShip == 1" @click="openDiscount(scope.row)" type="text" size="small">会员折扣
						</el-button>
						<div class="zk">
							<div class="zkboxAdd">
								<el-form ref="form" class="boxForm">
									<el-form-item>
										<div class="price-block">
											<div class="price-list">
												<el-form-item class="price-list-item">
													<div class="listbox">
														<span class="title">高级会员:</span>
														<span>原价</span>
														<el-input :disabled="true" v-model="yuanPrice"
															class="inpkuan" />
														<span>几折</span>
														<el-input  onkeyup="value=value.replace(/[^\d]/g,'')"  v-model="ratio1" @blur="blurNum"
															:maxlength="2"  class="inpkuan inp" />
														<span>优惠金额</span>
														<el-input  disabled v-model="ratioNum"   class="inpkuan" />
														<span>折后金额</span>
														<el-input  v-model="afterGj" disabled
															:maxlength="2"  class="inpkuan" />
													</div>
													<div class="listbox">
														<span class="title">首席会员:</span>
														<span>原价</span>
														<el-input :disabled="true" v-model="yuanPrice"
															class="inpkuan" />
														<span>几折</span>
														<el-input v-model="ratio2" @blur="blurNum2"  onkeyup="value=value.replace(/[^\d]/g,'')"
															  :maxlength="2" class="inpkuan inp" />
														<span>优惠金额</span>
														<el-input  disabled v-model="ratioNum2"   class="inpkuan" />
														<span>折后金额</span>
														<el-input  v-model="afterRy" disabled
															:maxlength="2"  class="inpkuan" />
													</div>
												</el-form-item>
												<div class="price-error-info" v-show="isPriceError"></div>
												<el-button  class="bttnn" type="primary" v-if="form.ratio2!= null || form.ratio1!= null"
													icon="el-icon-circle-plus-outline" size="mini"
													@click="handleAddPrice(scope.row)">修改</el-button>
												<el-button class="bttnn"  type="primary" v-else icon="el-icon-circle-plus-outline"
													size="mini" @click="handleAddPrice(scope.row)">确定</el-button>

												<el-button  class="bttnn" type="warning" icon="el-icon-circle-plus-outline" size="mini"
													@click="closeAddPrice(scope.row)">取消</el-button>
											</div>
										</div>
									</el-form-item>
								</el-form>
							</div>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<div class="tableOperate">
				<el-checkbox v-model="bottomChecked" class="bottomChecked" @change="toggleSelect">全选</el-checkbox>
				<el-button @click="handleAllRefresh" v-if="activeName==1">刷新</el-button>
				<el-button @click="handleAllShelf" v-if="activeName==1">下架</el-button>
				<el-button @click="handleAllPut" v-if="activeName==3">上架</el-button>
				<el-button @click="handleAllDelete">删除</el-button>
				<el-button @click="handleAllMain" v-if="activeName==1">设置热销</el-button>
				<el-button @click="handleAllNew" v-if="activeName==1">设置新品</el-button>
			</div>
			<div class="footer">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:page-sizes="[10, 20, 30]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
					:current-page.sync="currentPage" :total="total" background>
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import FillForm from '@/components/Product/FillForm'
	import * as math from 'mathjs'
	export default {
		data() {
			return {
				rowId: '',
				level: this.$store.state.I_EVEL,
				flagShip: this.$store.state.FLAG_SHIP,
				activeName: '1',
				search: '',
				cate: '',
				cateList: [],
				stars: '',
				total: 0, //数据总数
				pageSize: 10, //每页显示数量
				currentPage: 1, //当前页数
				list: [],
				total1: '',
				total0: '',
				total2: '',
				total3: '',
				bottomChecked: false,
				goodsCount: 0,
				goodsXia: 0,
				goodsShen: 0,
				surplusCount:0,
				showZk: false,
				yuanPrice: '',
				form: {
					ratio1: '',
					ratio2: '',
				},
				ratio2: '',
				ratio1: '',
				ratioNum: '',
				ratioNum2: '',
				afterGj:'',
				afterRy:'',
				pageNum:'',
				p:''
			}
		},
		components: {
			FillForm
		},
		created() {
			this.getData(this.activeName)
			this.$axios.post('/getSellerCategory').then(data => {
				this.cateList = data
			})
		},

		methods: {
			backNumOne(){ 
				this.currentPage = 1;
			},
			//只能输入正整数
			blurNum() {
				let rowPric = this.yuanPrice //原价
				let ratio1 = this.ratio1
				if(ratio1 == 0  || ratio1 == ''){
					this.ratioNum  = ""
				}else if(ratio1.length <= 1 && ratio1 != 0  && ratio1 != ''){
					ratio1=  this.printFn(ratio1 * 10)
					this.form.ratio1 = ratio1/100 //折数
					this.ratioNum  = -1*( rowPric - this.printFn(rowPric * this.form.ratio1)).toFixed(2)//减去了多少元    原价 - （原价*折数）
					this.afterGj = Number( this.yuanPrice  - (this.ratioNum)*-1)
				}else{
					this.form.ratio1 = ratio1/100 //折数
					this.ratioNum  = -1*( rowPric - this.printFn(rowPric * this.form.ratio1)).toFixed(2)//减去了多少元    原价 - （原价*折数）
					this.afterGj = Number( this.yuanPrice  - (this.ratioNum)*-1)
				}
			},
			blurNum2() {
				let rowPric = this.yuanPrice
				let ratio2 = this.ratio2
				if(ratio2 == 0 || ratio2 == ''){
					this.ratioNum2  = ""
				}else if(ratio2.length <= 1 && ratio2 != 0 && ratio2 != ''){
					ratio2=this.printFn(ratio2 * 10)
					this.form.ratio2 = ratio2/100 //折数
					this.ratioNum2  = -1*( rowPric - this.printFn(rowPric * this.form.ratio2)).toFixed(2)
					this.afterRy = Number( this.yuanPrice  - (this.ratioNum2)*-1)
				}else{
					this.form.ratio2 = ratio2/100 //折数
					this.ratioNum2  = -1*( rowPric - this.printFn(rowPric * this.form.ratio2)).toFixed(2)
					this.afterRy = Number( this.yuanPrice  - (this.ratioNum2)*-1)
				}
				
			},
			closeAddPrice() {
				$(".zk").hide()
			},
			handleAddPrice(row) {
				let rowPric = this.yuanPrice
				let ratio1 = String(this.ratio1)
				if(ratio1.length <= 1){
					ratio1= (ratio1*10/100).toFixed(2)
				}else if(ratio1.length == undefined){
					ratio1 = this.form.ratio1
				}else{
					ratio1=(ratio1/100).toFixed(2)
				}
				this.form.ratio1 = ratio1//折数
				let ratio2 =  String(this.ratio2)
				if(ratio2.length <= 1){
					ratio2= (ratio2 *10/100).toFixed(2)
				}else if(ratio2.length == undefined){
					ratio2 = this.form.ratio2
				}else{
					ratio2=(ratio2/100).toFixed(2)
				}
				this.form.ratio2 = ratio2//折数
				
				this.$axios.post('/setGoodsRatio', {
					id: this.rowId,
					ratio1: this.form.ratio1,
					ratio2: this.form.ratio2
				}).then(res => {
					if (res.code = '0000') {
						this.$message.success(res.msg);
						$(".zk").hide()
					}
				})
				this.getData(this.activeName)
			},
			//打开折扣
			hideDiscount() {
				$(".zk").hide()
			},
			openDiscount(row) {
				this.rowId = row.id
				var yuan = row.money *1
				this.$axios.post('/setGoodsRatio', {
					id: row.id
				}).then(res => {
					if (res.data) {
						let ratioNum = ((yuan - this.printFn(res.data.ratio1 * yuan)) *-1).toFixed(2)
						let ratioNum2 = ((yuan - this.printFn(res.data.ratio2 * yuan)) *-1).toFixed(2)
						let  ratio1= this.printFn(res.data.ratio1*100)
						let  a = ratio1 % 10
						let  ratio2= this.printFn(res.data.ratio2*100)
						let  b = ratio2 % 10
						if(a == 0){
							a = ratio1/10
						}else{
							a = ratio1
						}
						if(b == 0){
							b = ratio2/10
						}else{
							b = ratio2
						}
						this.ratio1 = a
						this.ratio2 = b
						
						if(a == 0){
							this.ratioNum='' 	
							this.afterGj = ""
						}else{
							this.ratioNum = ratioNum
							this.afterGj = Number( yuan - ratioNum*-1)
						}
						if(b == 0){
							this.ratioNum2='' 	
							this.afterRy  = ""
						}else{
							this.ratioNum2 = ratioNum2
							this.afterRy = Number(yuan - ratioNum2*-1)
						}
					} else {
						this.form.ratio1 = ''
						this.form.ratio2 = ''
						this.ratioNum = ''
						this.ratioNum2 = ''
						this.ratio1 = ''
						this.ratio2 = ''
						this.afterGj=""
						this.afterRy=""
					}
				})
				$(".zk").show()
				return this.yuanPrice = row.money2

			},
			printFn(value) {
				const precision = 14
				return Number(math.format(value, precision))
			},
			getData(state) {
				this.$axios.post('/sellerGoodsList', {
					limit:this.pageSize,
					state,
					page:this.currentPage
				}).then(data => {
					this.list = data.goodsList.map(item => {
						item.title2 = item.title
						item.money2 = item.money
						item.stars
						return item
					})
					this.total1 = data.goodsOk
					this.total0 = data.goodsShen
					this.total2 = data.goodsNo
					this.total3 = data.goodsXia
					//爱采购一键搬家
					sessionStorage.setItem("caijiOpen",data.caijiOpen)
					this.total = this['total' + state]
					if(this.activeName == 1){
						this.goodsCount = data.goodsCount
					}
					this.goodsShen  = data.goodsShen
					this.goodsXia  = data.goodsXia
					this.surplusCount = Number(200 - parseInt(this.goodsCount))
				})
			},
			getDate() {
				let dateTime
				let yy = new Date().getFullYear()
				let mm = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1
				let dd = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
				let hh = new Date().getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours()
				let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
				let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
				dateTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss
				return dateTime
			},
			postData(field, val, message, selectionId) {
				let ids = ''
				if (selectionId) {
					ids = selectionId
				} else {
					let selection = this.$refs.productTable.selection
					if (selection.length) {
						ids = selection.map(item => item.id)
					} else {
						this.$message.warning('请选择商品~')
						return
					}
				}
				this.$axios.post('/editGoodsField', {
					ids,
					field,
					val
				}).then(res => {
					this.$msgbox({
						message,
						type: 'success'
					})
				})
			},
			handleClick({
				name
			}) {
				this.activeName = name
				this.getData(name)
			},
			handleSizeChange(val) {
				this.pageSize = val
				this.pageNum=val
				this.getData(this.activeName)
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.p =val
				let activeName = Number(this.activeName)
				this.getData(activeName)
			},
			toggleSelect(state) {
				if (state) {
					this.$refs.productTable.toggleAllSelection(this.newList)
				} else {
					this.$refs.productTable.clearSelection()
				}
			},

			handleSelect(data) {
				if (data.length == this.newList.length) {
					this.bottomChecked = true
				} else {
					this.bottomChecked = false
				}
			},
			handleAllRefresh() {
				this.postData('update_time', this.getDate(), '刷新成功')
			},
			handleAllShelf() {
				this.postData('state', 3, '下架成功')
				location.reload()
			},
			handleAllDelete() {
				this.$confirm('此操作将永久删除产品, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.postData('state', 4, '删除成功')
					location.reload()
				}).catch()
			},
			handleAllMain() {
				let selection = this.$refs.productTable.selection
				let isHot = selection.some(item => item.is_hot == 1)
				if (!isHot) {
					this.postData('is_hot', 1, '设置成功')
				} else {
					this.$message.warning('您选择的产品中已有热销行业，不可以重复设置');
				}
			},
			handleAllNew() {
				let selection = this.$refs.productTable.selection
				let isBest = selection.some(item => item.is_best == 1)
				if (!isBest) {
					this.postData('is_best', 1, '设置成功')
				} else {
					this.$message.warning('您选择的产品中已有新品行业，不可以重复设置');
				}
			},
			handleAllPut() {
				this.postData('state', 1, '上架成功')
				location.reload()
			},
			handleSearch() {
				if (this.search == '' && this.cate == '' && this.stars == '') {
					this.$message.warning('请填写筛选条件')
					return
				}
				this.$axios.post('/sellerGoodsList', {
					title: this.search,
					cat_id: this.cate,
					stars: this.stars,
					state: this.activeName,
					pageNum:this.pageSize,
					p:this.currentPage
				}).then(data => {
					this.list = data.goodsList.map(item => {
						item.title2 = item.title
						item.money2 = item.money
						return item
					})
					this.total1 = data.goodsOk
					this.total0 = data.goodsShen
					this.total2 = data.goodsNo
					this.total3 = data.goodsXia
					this.total = this['total' + this.activeName]
				})
			},
			handleToSee({
				id
			}) {
				open(`${this.$store.state.ROOT_URL}/product/${id}.html`)
			},
			handleToRevise(row) {
				this.$router.push(`/product/revise?id=${row.id}`)
			},
			handleRepeat(row) {
				this.$router.push(`/product/copy?id=${row.id}`)
			},
			// handleSee() {
			// 	$(".zk").show()
			// },
			// 上架 单独的
			handleup({
				id
			}) {
				this.postData('state', 1, '上架成功', id)
				location.reload()
			},
			handleRefresh({
				id
			}) {
				this.postData('update_time', this.getDate(), '刷新成功', id)
			},
			handleDelete({
				id
			}) {
				this.$confirm('此操作将永久删除产品, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.postData('state', 4, '删除成功', id)
					location.reload()
				}).catch()
			},
			handleHotChange(row) {
				this.postData('is_hot', row.is_hot, '修改成功', row.id)
			},
			
			handleBestChange(row) {
				this.postData('is_best', row.is_best, '设置成功', row.id)
			},
			popoverTitleShow(row) {
				row.title2 = row.title
			},
			handleEditTitle(row) {
				if (row.title2 == row.title) {
					this.$message.warning('相同名称不能重复提交')
					return
				}
				this.postData('title', row.title2, '修改成功', row.id)
				row.title = row.title2
			},
			popoverMoneyShow(row) {
				row.money2 = row.money
			},
			handleEditMoney(row) {
				if (row.money2 == row.money) {
					this.$message.warning('相同价格不能重复提交')
					return
				}
				this.postData('money', row.money2, '修改成功', row.id)
				row.money = row.money2
			},
			handleAdd() {
				this.level = this.$store.state.I_EVEL
				let count = Number(this.goodsShen +  this.goodsCount + this.goodsXia)
				if (count >= 200 && this.level == 1) {
					this.$confirm('您现在为荣誉会员最多可发布200条产品信息，如果发布更多请升级到更高等级会员!', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						localStorage.setItem('toId', "vip");
						this.$router.push('/')
					}).catch(() => {});
				} else {
					this.$router.push('/product/release')
				}
			}
		},
		computed: {
			newList() {
				document.body.scrollTop = document.documentElement.scrollTop = 0
				// return this.list.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
				return this.list
			}
		},
		watch: {
			"forms"(newVal, oldVal) {
				// console.log("新值：" + newVal, "旧值：" + oldval)
			},
			"list"(value){
				// console.log("改变了？？",value)
			}
		}
	}
</script>

<style scoped>
	.wrap{
		width: 1600px;
		background-color: #fff;
	}
	.ships{
		font-size: 12px;
	}
	.header {
		padding: 0 28px;
		height: 77px;
		border-bottom: 1px solid #e0e0e0;
		display: flex;
		font-size: 18px;
		color: #000000;
		align-items: center;
		font-weight: 700;
	}

	.header::before {
		content: '';
		width: 4px;
		height: 20px;
		background: #0e69db;
		margin-right: 10px;
	}

	.content {
		padding: 0 28px;
	}

	.tip {
		padding: 21px 32px;
		font-size: 14px;
		color: #666666;
		background: #ecf4ff;
		border-radius: 8px;
	}

	.tip p {
		height: 27px;
		line-height: 27px;
		display: flex;
	}

	.tip a {
		padding: 0 11px;
		height: 26px;
		line-height: 26px;
		color: #fff;
		background: #0194fe;
		border-radius: 5px;
		margin-left: 30px;
	}

	.screen {
		margin-top: 36px;
	}

	.wrap>>>.el-table th {
		background: #e3e4e7;
		color: #120500;
	}

	.tableOperate {
		margin-top: 30px;
	}

	.bottomChecked {
		margin: 0 14px;
	}

	.footer {
		padding: 75px 0 86px;
		text-align: center;
	}

	.footer>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
		background: #ff4344;
	}

	.footer>>>.el-pagination.is-background .el-pager li:not(.disabled):hover {
		color: #C0C4CC;
	}

	.stars {
		width: 15px;
		height: 15px;
		background: url(../../assets/img/stars.png) no-repeat;
		background-size: 100% 100%;
	}

	.tit-wrap {
		display: flex;
	}

	.tit-img {
		width: 58px;
		height: 58px;
		border: 1PX solid #eeeeee;
		flex: none;
	}

	.tit-img img {
		width: 100%;
		height: 100%;
	}

	.tit-info {
		margin-left: 7px;
		align-items: center;
		font-size: 12px;
		overflow: hidden;
	}

	.wrap .tit-name {
		display: flex;
		height: 20px;
		line-height: 20px;
		align-items: center;
		color: #120500;
	}

	.tit-name span {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.tit-name i {
		margin-left: 10px;
		font-size: 14px;
		cursor: pointer;
	}

	.wrap .tit-num {
		height: 20px;
		line-height: 20px;
		color: #666666;
		display: flex;
	}

	.tit-num span {
		margin-right: 18px;
	}

	.wrap .tit-qr {
		display: flex;
		height: 19px;
		line-height: 19px;
		align-items: center;
		cursor: pointer;
	}

	.icon-qr {
		width: 12px;
		height: 12px;
		background: url(../../assets/img/icon-qr.png);
		background-size: 100%;
	}

	.tit-qr span {
		color: #666666;
		margin-left: 5px;
	}

	.share-qr {
		width: 130px;
		height: 130px;
		margin: 10px auto;
	}

	.share-qr img {
		width: 100%;
		height: 100%;
	}

	.edit-name-form .el-form-item--mini.el-form-item,
	.el-form-item--small.el-form-item {
		margin-bottom: 0;
	}

	.money-wrap {
		/* display: flex; */
		/* align-items: center; */
	}

	.money-wrap i {
		margin-left: 5px;
		cursor: pointer;
	}

	.add-btn {
		margin: 15px 0;
	}

	.zk {
		width: 760px;
		height: 360px;
		position: fixed;
		right: 0;
		left: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		background-color: #fff;
		border-radius: 10px;
		box-shadow: 1px 1px 15px #ccc;
		z-index: 5;
		display: none;
	}

	.boxSpan {
		padding: 10px 0;
	}

	.zhekou {
		margin-top: 30px;
	}

	.logist_btn {
		/* display: flex;
		justify-content: space-around; */
		text-align: center;
	}

	.boxForm {
		margin-top: 30px;
	}

	.inpkuan {
		width: 123px;
	}

	.listbox {
		display: inline-block;
		padding: 20px 0;
	}

	.zkboxAdd {
		text-align: center;
	}
	.title{
		font-size: 16px;
		display: block;
		text-align: left;
		margin-left: -30px;
	}
	.inp{
		width: 50px;
	}
	.bttnn{
		padding: 13px 22px;
		font-size: 14px;
	}
	.wrap .el-tabs__nav-wrap:after{
		background-color: rgba(0,0,0,0) !important;
	}
</style>
